<template>
  <v-container class="bl_errorPage">

    <v-row class="pt-8 pb-4 px-4">
      <v-col cols=12 class="pa-0">
        <p
          class="text-h5" 
        >よくある質問</p>
      </v-col>
    </v-row>

    <v-row justify="center" class="mb-12">
      <v-col cols="12" md="10">
        <h4 class="mt-8 mb-1">アップロード関連</h4>
        <v-expansion-panels multiple accordion>
          <v-expansion-panel class="bl_faq_unit">
            <v-expansion-panel-header>
              <div class="bl_faq_question">ファイル名に使ってはいけない文字はありますか？</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="primary--text bl_faq_anser">
              ファイルチェックシステム上では特にありません。
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="bl_faq_unit">
            <v-expansion-panel-header>
              <div class="bl_faq_question">計算結果PDFファイルをZIPにまとめてアップロードすることはできますか？</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="primary--text bl_faq_anser">
              できません。PDFファイルとしてアップロードしてください。
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="bl_faq_unit">
            <v-expansion-panel-header>
              <div class="bl_faq_question">ファイルアップロード中に表示するタブを変えたりPCがスリープしたりしても問題ありませんか？</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="primary--text bl_faq_anser">
              問題ありません。
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="bl_faq_unit">
            <v-expansion-panel-header>
              <div class="bl_faq_question">ファイルアップロード中にタイムアウトしてしまいます。</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="primary--text bl_faq_anser">
              ファイルアップロードの際にファイルサイズが大きいなどの理由でタイムアウトする場合があります。
              その場合はアップロードしたファイルの中身やファイルサイズをご確認ください。「一次エネルギー消費量計算結果(住宅版)」のファイルサイズはおおむね100KB以下です。
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="bl_faq_unit">
            <v-expansion-panel-header>
              <div class="bl_faq_question">アップロードしたファイルはどのように管理されますか？</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="primary--text bl_faq_anser">
              Google Cloudを利用しファイルアップロードの安全を確保しています。一時ファイルは時間経過で削除されます。
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <h4 class="mt-8 mb-1">チェック関連</h4>
        <v-expansion-panels multiple accordion>
          <v-expansion-panel class="bl_faq_unit">
            <v-expansion-panel-header>
              <div class="bl_faq_question">このチェックシステムでは計算結果の適合判定もチェックされますか？省エネ基準に不適合の場合はどこかに表示されますか？</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="primary--text bl_faq_anser">
              されません。ファイルチェック画面に平均BEIが表示されますが、あくまで参考値となります。
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="bl_faq_unit">
            <v-expansion-panel-header>
              <div class="bl_faq_question">報告方法ⅱにおいて、同じ住宅タイプが１戸しかないものを含む場合、ファイル名の末尾を[1]（ファイル名をxxxxx[1].pdf）として登録可能ですか？別途報告方法ⅰで登録するべきでしょうか？</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="primary--text bl_faq_anser">
              報告方法ⅱとして登録可能です。
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="bl_faq_unit">
            <v-expansion-panel-header>
              <div class="bl_faq_question">チェックを通ったファイルをZIPのダウンロード前に差し替えることはできますか？</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="primary--text bl_faq_anser">
              できません。チェックが通ったファイルを差し替える場合は、戻るボタンより全てのアップロードファイルを破棄して最初から操作してください。
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="bl_faq_unit">
            <v-expansion-panel-header>
              <div class="bl_faq_question">平均BEI（参考値）はどのように計算された値ですか？</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="primary--text bl_faq_anser">
              ファイルチェックが通った各PDFファイルの設計一次エネルギー消費量（その他の設計一次エネルギー消費量を除く）[GJ/yr] の合計と基準一次エネルギー消費量（その他の基準一次エネルギー消費量を除く）[GJ/yr]の合計を用いて算出した値です。
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        
        <h4 class="mt-8 mb-1">システム全体</h4>
        <v-expansion-panels multiple accordion>
          <v-expansion-panel class="bl_faq_unit">
            <v-expansion-panel-header>
              <div class="bl_faq_question">推奨環境を教えてください。</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="primary--text bl_faq_anser">
              ・Chrome 最新バージョンのもの<br />
              ・Microsoft Edge 最新バージョンのもの<br />
              <small>Firefoxではサーバーが混み合っている場合にファイルチェックがすすまないことがあります。時間をおいて再度お試しください。</small>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        
        <h4 class="mt-8 mb-1">エラー関連</h4>
        <v-expansion-panels multiple accordion>
          <v-expansion-panel class="bl_faq_unit">
            <v-expansion-panel-header>
              <div class="bl_faq_question">アップロードしようとすると、「アップロードできるファイルは1MB以下のPDFファイルのみです。ファイルをご確認ください。」と表示されます。</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="primary--text bl_faq_anser">
              ファイルサイズが1MB以上のPDFファイルをアップロードすることはできません。それ以外のファイルをアップロードし直してください。
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="bl_faq_unit">
            <v-expansion-panel-header>
              <div class="bl_faq_question">「住宅の建て⽅が正しくありません。」というエラーが表示されます。</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="primary--text bl_faq_anser">
              本システムの画面上で選択された住宅の建て方とアップロードされたファイルの住宅の建て方が異なっています。削除または差し替えで対応してください。
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="bl_faq_unit">
            <v-expansion-panel-header>
              <div class="bl_faq_question">「一次エネルギー消費量計算結果(住宅版)」ファイルではない、もしくは加工されたファイルです。」というエラーが表示されます。</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="primary--text bl_faq_anser">
              アップロードされたファイルが「一次エネルギー消費量計算結果（住宅版）」のファイルではない、またはスキャン等の加工がされたファイルです。削除または差し替えで対応してください。
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="bl_faq_unit">
            <v-expansion-panel-header>
              <div class="bl_faq_question">「報告方法 ii を選択していますが、ファイル名に戸数がありません。」というエラーが表示されます。</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="primary--text bl_faq_anser">
              報告方法として報告方法ⅱを選択した場合はファイル名の末尾に戸数の表記が必要です。詳細はマニュアルをご覧ください。削除または差し替えで対応してください。
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="bl_faq_unit">
            <v-expansion-panel-header>
              <div class="bl_faq_question">「ZIPファイルのダウンロード処理に失敗しました。」というエラーが表示されます。</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="primary--text bl_faq_anser">
              お使いの環境やブラウザの設定によりZIPファイルのダウンロード処理に不具合が生じる場合があります。申し訳ありませんが、お手元のPDFファイルを提出物として届出ください。
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

  </v-container>
</template>
<script>
export default {
  components: { },

  data() {
    return {
    }
  },

  computed: {
  },

  methods: {

  }
}
</script>

<style scoped>
.bl_faq_unit{   
	counter-increment: num 1;
}
.bl_faq_question{
  display: grid;
  grid-template-columns: 3em 1fr;
  align-items: baseline;
  line-height: 1.5;
}
.bl_faq_question:before{
  content: "Q" counter(num) "."; 
  display:inline-flex;
  width: 32px;
  font-size: 1.2em;
}
.bl_faq_anser{
  display: grid;
  grid-template-columns: 3em 1fr;
  align-items: baseline;
  margin-bottom: 1.5em;
  padding: 4px 4em 0 1.5em;
  line-height: 1.5;
}
.bl_faq_anser:before{
  content: "A" counter(num) "."; 
  display:inline-flex;
  width: 32px;
  font-size: 1.2em;
}
.bl_faq_anser >>> .v-expansion-panel-content__wrap{
  padding:0;
}
</style>